// .main {

// }

.article {
	&-header, &-section {
		margin-bottom: 70px;
		@include sm {
			margin-bottom: 12vw;
		}
	}

	&-header {
		img {
			display: block;
			width: 470px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 50px;
		}
		p {
			font-size: 20px;
			font-weight: bold;
			@include sm {
				font-size: 4vw;
			}
		}
	}

	&-section {
		h2 {
			margin-bottom: 20px;
		}
		> p {
			&:first-child {
				margin-bottom: 80px;
				@include sm {
					margin-bottom: 10vw;
				}
			}
		}
	}

	&-img {
		p {
			padding: 30px 0;
			iframe, img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 600px;
				height: 400px;
				@include md {
					width: 90%;
					height: 55.208333333333333vw;
				}
			}
		}
	}

	&-problem {
		padding: 60px 0;
		border-top: 1px solid;
		border-bottom: 1px solid;
		h2, p {
			text-align: center;
		}
	}

	&-list {
		> ul > li {
			position: relative;
			margin-bottom: 2px;
			a {
				padding: 22px 0;
				display: block;
				span {
					display: inline-block;
					vertical-align: middle;
					&:last-child {
						width: 80%;
					}
				}
			}
		}
		.icon {
			&-arrow-r {
				margin: 0 15px;
			}
		}
	}

	&-item {
		article {
			width: 204px;
			margin-bottom: 60px;
			@include md {
				width: 25%;
			}
			@include sm {
				width: 50%;
				margin-bottom: 10vw;
			}
		}

		h3 {
			text-align: center;
			font-size: 15px;
			margin-top: 10px;
			@include md {
				font-size: 13px;
			}
			@include sm {
				font-size: 4.0vw;
			}
		}

		.icon {
			&-arrow-r {
				margin-right: 7px;
				margin-left: -7px;
			}
		}
	}
}

.problem-box {
	border: 1px solid;
	> p {
		font-weight: bold;
	}

	&[data-problem="step2"], &[data-problem="step3"] {
		> p {
			margin-bottom: 20px;
		}
	}
}

#faq {
	// &-top {

	// }
	// &-product {

	// }

	&-detail {
		.article-header h1 {
			padding-bottom: 20px;
			border-bottom: 1px solid;
		}
	}
}