@charset "UTF-8";
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  font-size: 62.5%;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-feature-settings: "palt";
  line-height: 2.0;
  letter-spacing: 0.1em;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  background: transparent;
  transition: all .3s;
}

a:hover {
  opacity: 0.7;
}

a, u {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 3.2rem;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 460px) {
  h1 {
    font-size: 6vw;
  }
}

h2 {
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 460px) {
  h2 {
    font-size: 5vw;
  }
}

h3 {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 460px) {
  h3 {
    font-size: 4.7vw;
  }
}

h4 {
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  h4 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 460px) {
  h4 {
    font-size: 4.2vw;
  }
}

h5 {
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  h5 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 460px) {
  h5 {
    font-size: 4vw;
  }
}

h6 {
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  h6 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 460px) {
  h6 {
    font-size: 3.6vw;
  }
}

div, main, header, footer, nav, aside, p {
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  div, main, header, footer, nav, aside, p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 460px) {
  div, main, header, footer, nav, aside, p {
    font-size: 3.6vw;
  }
}

.breadcrumb {
  padding: 20px 0;
  margin-bottom: 80px;
  overflow-x: hidden;
}

@media screen and (max-width: 460px) {
  .breadcrumb {
    margin-bottom: 10vw;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb ol {
    overflow-x: scroll;
    padding-bottom: 2vw;
    -webkit-overflow-scrolling: touch;
  }
}

.breadcrumb ol li {
  font-size: 12px;
  white-space: nowrap;
}

.breadcrumb ol li a {
  border-bottom: 1px solid;
}

.btn {
  display: block;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 6px;
  line-height: 1.4;
  user-select: none;
  cursor: pointer;
}

.btn a {
  display: block;
  width: 100%;
  height: 100%;
}

.problem .btn {
  width: 280px;
  height: 60px;
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
}

@media screen and (max-width: 460px) {
  .problem .btn {
    margin-bottom: 20px;
  }
}

.problem .btn span {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.problem-box {
  padding: 30px;
}

.footer {
  margin-top: 120px;
  text-align: center;
  padding: 15px;
}

@media screen and (max-width: 460px) {
  .footer {
    margin-top: 15vw;
  }
}

.footer small {
  font-size: 1.2rem;
  font-weight: bold;
}

.header {
  padding: 30px 0;
}

.header .logo {
  width: 210px;
}

@media screen and (max-width: 460px) {
  .header .logo {
    width: 50%;
  }
}

.header .logo img {
  width: 135px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 460px) {
  .header .logo img {
    width: 60%;
  }
}

.header .logo:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  border-top: 1px solid;
  margin: 0 15px;
}

.header p {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 460px) {
  .header p {
    font-size: 5.0vw;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-arrow-r {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}

.icon-arrow-r:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotateZ(45deg);
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: -2px;
  right: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.breadcrumb li:not(:first-child):before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotateZ(45deg);
  margin-left: 12px;
  margin-right: 15px;
}

.container {
  width: 1060px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 4%;
  }
}

.grid {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.grid-top {
  align-items: flex-start;
}

.grid-middle {
  align-items: center;
}

.grid-bottom {
  align-items: flex-end;
}

.grid-center {
  justify-content: center;
}

.grid-end {
  justify-content: flex-end;
}

.grid-between {
  justify-content: space-between;
}

.grid-nowrap {
  flex-wrap: nowrap;
}

.grid-reverse {
  flex-direction: row-reverse;
}

.grid-column {
  flex-direction: column;
}

.grid-column-reverse {
  flex-direction: column-reverse;
}

.grid-order-1 {
  order: 1;
}

.grid-grow-1 {
  flex-grow: 1;
}

.grid-shrink-1 {
  flex-shrink: 1;
}

.grid-order-2 {
  order: 2;
}

.grid-grow-2 {
  flex-grow: 2;
}

.grid-shrink-2 {
  flex-shrink: 2;
}

.grid-order-3 {
  order: 3;
}

.grid-grow-3 {
  flex-grow: 3;
}

.grid-shrink-3 {
  flex-shrink: 3;
}

.grid-order-4 {
  order: 4;
}

.grid-grow-4 {
  flex-grow: 4;
}

.grid-shrink-4 {
  flex-shrink: 4;
}

.grid-order-5 {
  order: 5;
}

.grid-grow-5 {
  flex-grow: 5;
}

.grid-shrink-5 {
  flex-shrink: 5;
}

.grid-order-6 {
  order: 6;
}

.grid-grow-6 {
  flex-grow: 6;
}

.grid-shrink-6 {
  flex-shrink: 6;
}

.grid-order-7 {
  order: 7;
}

.grid-grow-7 {
  flex-grow: 7;
}

.grid-shrink-7 {
  flex-shrink: 7;
}

.grid-order-8 {
  order: 8;
}

.grid-grow-8 {
  flex-grow: 8;
}

.grid-shrink-8 {
  flex-shrink: 8;
}

.grid-order-9 {
  order: 9;
}

.grid-grow-9 {
  flex-grow: 9;
}

.grid-shrink-9 {
  flex-shrink: 9;
}

.grid-order-10 {
  order: 10;
}

.grid-grow-10 {
  flex-grow: 10;
}

.grid-shrink-10 {
  flex-shrink: 10;
}

.grid-order-11 {
  order: 11;
}

.grid-grow-11 {
  flex-grow: 11;
}

.grid-shrink-11 {
  flex-shrink: 11;
}

.grid-order-12 {
  order: 12;
}

.grid-grow-12 {
  flex-grow: 12;
}

.grid-shrink-12 {
  flex-shrink: 12;
}

.article-header, .article-section {
  margin-bottom: 70px;
}

@media screen and (max-width: 460px) {
  .article-header, .article-section {
    margin-bottom: 12vw;
  }
}

.article-header img {
  display: block;
  width: 470px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.article-header p {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 460px) {
  .article-header p {
    font-size: 4vw;
  }
}

.article-section h2 {
  margin-bottom: 20px;
}

.article-section > p:first-child {
  margin-bottom: 80px;
}

@media screen and (max-width: 460px) {
  .article-section > p:first-child {
    margin-bottom: 10vw;
  }
}

.article-img p {
  padding: 30px 0;
}

.article-img p iframe, .article-img p img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  height: 400px;
}

@media screen and (max-width: 768px) {
  .article-img p iframe, .article-img p img {
    width: 90%;
    height: 55.208333333333333vw;
  }
}

.article-problem {
  padding: 60px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.article-problem h2, .article-problem p {
  text-align: center;
}

.article-list > ul > li {
  position: relative;
  margin-bottom: 2px;
}

.article-list > ul > li a {
  padding: 22px 0;
  display: block;
}

.article-list > ul > li a span {
  display: inline-block;
  vertical-align: middle;
}

.article-list > ul > li a span:last-child {
  width: 80%;
}

.article-list .icon-arrow-r {
  margin: 0 15px;
}

.article-item article {
  width: 204px;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .article-item article {
    width: 25%;
  }
}

@media screen and (max-width: 460px) {
  .article-item article {
    width: 50%;
    margin-bottom: 10vw;
  }
}

.article-item h3 {
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .article-item h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 460px) {
  .article-item h3 {
    font-size: 4.0vw;
  }
}

.article-item .icon-arrow-r {
  margin-right: 7px;
  margin-left: -7px;
}

.problem-box {
  border: 1px solid;
}

.problem-box > p {
  font-weight: bold;
}

.problem-box[data-problem="step2"] > p, .problem-box[data-problem="step3"] > p {
  margin-bottom: 20px;
}

#faq-detail .article-header h1 {
  padding-bottom: 20px;
  border-bottom: 1px solid;
}

.is-block {
  display: block;
}

.is-inline {
  display: inline;
}

.is-inline-block {
  display: inline-block;
}

.is-show {
  display: block;
}

.is-hidden {
  display: none;
}

.is-hidden-xy {
  overflow: hidden;
}

.is-hidden-x {
  overflow-x: hidden;
}

.is-hidden-y {
  overflow-y: hidden;
}

.is-visible-xy {
  overflow: visible;
}

.is-visible-x {
  overflow-x: visible;
}

.is-visible-y {
  overflow-y: visible;
}

.is-scroll-xy {
  overflow: scroll;
}

.is-scroll-x {
  overflow-x: scroll;
}

.is-scroll-y {
  overflow-y: scroll;
}

a {
  color: #000000;
}

.btn:hover {
  background-color: #4c4c4c;
  color: #ffffff;
}

.btn-white {
  background-color: #ffffff;
  color: #ffffff;
  border-color: #000000;
  color: #000000;
}

.btn-white a {
  color: #ffffff;
}

.btn-white a {
  color: #000000;
}

.btn-gray-1 {
  background-color: #f0f0f0;
  color: #ffffff;
  border-color: #000000;
}

.btn-gray-1 a {
  color: #ffffff;
}

.btn-gray-2 {
  background-color: #dddddd;
  color: #ffffff;
  border-color: #000000;
}

.btn-gray-2 a {
  color: #ffffff;
}

.btn-gray-3 {
  background-color: #4c4c4c;
  color: #ffffff;
  border-color: #000000;
}

.btn-gray-3 a {
  color: #ffffff;
}

.btn-gray-4 {
  background-color: #555655;
  color: #ffffff;
  border-color: #000000;
}

.btn-gray-4 a {
  color: #ffffff;
}

.btn-gray-5 {
  background-color: #666666;
  color: #ffffff;
  border-color: #000000;
}

.btn-gray-5 a {
  color: #ffffff;
}

.btn-black {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}

.btn-black a {
  color: #ffffff;
}

.header {
  background-color: #000000;
  color: #ffffff;
}

.breadcrumb {
  background-color: #f0f0f0;
}

.breadcrumb li:before {
  border-top-color: #555655;
  border-right-color: #555655;
}

.breadcrumb li a {
  color: #555655;
}

.article-header p {
  color: #666666;
}

.article-header h1 {
  border-bottom-color: #dddddd;
}

.article-img p {
  background-color: #f0f0f0;
}

.article-problem {
  border-top-color: #dddddd;
  border-bottom-color: #dddddd;
}

.article-list > ul > li {
  background-color: #f0f0f0;
}

.article-item .icon-arrow-r {
  background-color: #dddddd;
}

.problem-box {
  background-color: #f0f0f0;
  border-color: #000000;
}

.icon-arrow-r {
  background-color: #ffffff;
}

.icon-arrow-r:before {
  border-top-color: #000000;
  border-right-color: #000000;
}

.footer {
  background-color: #000000;
}

.footer small {
  color: #ffffff;
}
