.is-block        { display: block; }
.is-inline       { display: inline; }
.is-inline-block { display: inline-block; }

// .is-show, .is-hidden
//-------------------------------------------------------
.is-show       { display: block; }
.is-hidden     { display: none; }

// overflow
//-------------------------------------------------------
.is-hidden-xy  { overflow:   hidden; }
.is-hidden-x   { overflow-x: hidden; }
.is-hidden-y   { overflow-y: hidden; }

.is-visible-xy { overflow:   visible; }
.is-visible-x  { overflow-x: visible; }
.is-visible-y  { overflow-y: visible; }

.is-scroll-xy  { overflow:   scroll; }
.is-scroll-x   { overflow-x: scroll; }
.is-scroll-y   { overflow-y: scroll; }

//
//-------------------------------------------------------
// .is-active {}


//
//-------------------------------------------------------

// .is-error {}