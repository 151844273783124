.btn {
  display: block;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 6px;
  line-height: 1.4;
  user-select: none;
  cursor: pointer;
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.problem {
  .btn {
    width: 280px;
    height: 60px;
    position: relative;
    margin-right: 15px;
    margin-left: 15px;
    @include sm {
      margin-bottom: 20px;
    }
    span {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &-box {
    padding: 30px;
  }
}