@mixin font-size( $value-desktop, $value-tablet, $value-mobile ) {
  font-size : $value-desktop;
  @media screen and (max-width: $width-md) {
    font-size : $value-tablet;
  }
  @media screen and (max-width: $width-sm) {
    font-size : $value-mobile;
  }
}

@mixin arrow-icon( $width, $height, $ml, $mr ) {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: $width;
  height: $height;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotateZ(45deg);
  margin-left: $ml;
  margin-right: $mr;
}

//----------------------------------------------------

// 横並びの作成
@mixin grid( $display: flex, $jc: flex-start, $fw: wrap, ) {
  display:         $display;
  justify-content: $jc;
  flex-wrap:       $fw;
}

//----------------------------------------------------

// Desktopのみブレイクポイント
@mixin lg {
  @media screen and (min-width: $width-md + 1) {
    @content;
  }
}

// タブレット用ブレイクポイント
@mixin md {
  @media screen and (max-width: $width-md) {
    @content;
  }
}

// スマホ用ブレイクポイント
@mixin sm {
  @media screen and (max-width: $width-sm) {
    @content;
  }
}
