.header {
	padding: 30px 0;
	.logo {
		width: (135px + 75px);
		@include sm {
			width: 50%;
		}
		img {
			width: 135px;
			display: inline-block;
			vertical-align: middle;
			@include sm {
				width: 60%;
			}
		}
		&:after {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 30px;
			border-top: 1px solid;
			margin: 0 15px;
		}
	}

	p {
		font-size: 20px;
		font-weight: bold;
		@include sm {
			font-size: 5.0vw;
		}
	}
}