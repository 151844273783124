@import "../var";
// テーマルール (カラーの指定)
//-------------------------------------------------------

// テーマルールでは、サイト全体の見た目の雰囲気を統一させるための定義をします
// 色に関わる部分などがテーマの管理対象となるので、例えば以下のように色に関するスタイルは、独立させて記述します。

// main.css
  // .box {
  //     border: 1px solid;
  // }

// theme.css
  // .box {
  //     background-color: #eee;
  //     border-color: #ccc;
  // }


//-------------------------------------------------------

a {
  color: $color-black;
}

//-------------------------------------------------------

.btn {
  &:hover {
    background-color: $color-gray-3;
    color: $color-white;
  }
}

//-------------------------------------------------------

@each $key, $value in $colors {

  // .btn-black, .btn-white, etc...
  .btn-#{$key} {
    background-color: $value;
    color: $color-white;
    border-color: $color-black;
    a {
      color: $color-white;
    }
    @if $key == 'white' {
      color: $color-black;
      a {
        color: $color-black;
      }
    }
  }

}


//-------------------------------------------------------

.header {
  background-color: $color-black;
  color: $color-white;
}

.breadcrumb {
  background-color: $color-gray-1;
	li {
		&:before {
			border-top-color  : $color-gray-4;
			border-right-color: $color-gray-4;
		}
		a {
			color: $color-gray-4;
		}
	}
}

.article {
	&-header {
		p {
      color: $color-gray-5;
    }

    h1 {
      border-bottom-color: $color-gray-2;
    }
  }

  &-img {
    p {
      background-color: $color-gray-1;
    }
  }

  &-problem {
		border-top-color  : $color-gray-2;
    border-bottom-color: $color-gray-2;
  }

  &-list {
		> ul > li {
      background-color: $color-gray-1;
    }
  }

  &-item {
    .icon {
      &-arrow-r {
        background-color: $color-gray-2;
      }
    }
  }
}

.problem-box {
  background-color: $color-gray-1;
  border-color: $color-black;
}

.icon {
	&-arrow-r {
		background-color: $color-white;
		&:before {
			border-top-color: $color-black;
			border-right-color: $color-black;
		}
	}
}


.footer {
	background-color: $color-black;
	small {
		color: $color-white;
	}
}