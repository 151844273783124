// Table of Contents
//------------------------------------------
// Prefix
// Color
// Width
// Fonts
// Align
// Grid
//------------------------------------------


// Prefix
//------------------------------------------
$breakpoints: "-", "-xl-", "-lg-", "-md-", "-sm-";


// Color
//------------------------------------------

$color-white    :#ffffff !default;
$color-gray-1   :#f0f0f0 !default;
$color-gray-2   :#dddddd !default;
$color-gray-3   :#4c4c4c !default;
$color-gray-4   :#555655 !default;
$color-gray-5   :#666666 !default;
$color-black    :#000000 !default;

$colors: () !default;
$colors: map-merge(
  (
   "white"  : $color-white,
   "gray-1" : $color-gray-1,
   "gray-2" : $color-gray-2,
   "gray-3" : $color-gray-3,
   "gray-4" : $color-gray-4,
   "gray-5" : $color-gray-5,
   "black"  : $color-black,
  ),
  $colors
);


// Width
//------------------------------------------

$width-lg:1020px;
$width-md: 768px;
$width-sm: 460px;


// Fonts
//------------------------------------------

$font-gothic : "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;


// Align
//------------------------------------------

$align: (
  center,
  left,
  right,
  top,
  middle,
  bottom,
);


// Grid
//------------------------------------------

$grid-column: 12;

