.breadcrumb {
	padding: 20px 0;
	margin-bottom: 80px;
	overflow-x: hidden;
	@include sm {
		margin-bottom: 10vw;
	}
	ol {
		@include md {
			overflow-x: scroll;
			padding-bottom: 2vw;
			-webkit-overflow-scrolling: touch;
		}
		li {
			font-size: 12px;
			white-space: nowrap;
			a {
				border-bottom: 1px solid;
			}
		}
	}
}