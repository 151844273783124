.footer {
	margin-top: 120px;
	text-align: center;
	padding: 15px;
	@include sm {
		margin-top: 15vw;
	}
	small {
		font-size: 1.2rem;
		font-weight: bold;
	}
}