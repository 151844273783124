// ベースとなる指定を記述
// カラーや状態は、themeやstateに記載
//-------------------------------------------------------

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
}
*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  font-size: 62.5%;
  font-family: $font-gothic;
  font-feature-settings: "palt";
  line-height: 2.0;
  letter-spacing: 0.1em;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display: block;
}

ul, ol {
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  background: transparent;
  transition: all .3s;
  &:hover {
    opacity: 0.7;
  }
}

a, u {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}


h1 { @include font-size( 3.2rem, 3.2rem, 6.0vw); }
h2 { @include font-size( 2.4rem, 2.4rem, 5.0vw); }
h3 { @include font-size( 2.0rem, 2.0rem, 4.7vw); }
h4 { @include font-size( 1.8rem, 1.8rem, 4.2vw); }
h5 { @include font-size( 1.6rem, 1.6rem, 4.0vw); }
h6 { @include font-size( 1.4rem, 1.4rem, 3.6vw); }


div, main, header, footer, nav, aside, p {
  @include font-size( 1.6rem, 1.6rem, 3.6vw );
}