.container {
  width: $width-lg + 40;
  padding: 0 20px;
	margin-left: auto;
	margin-right: auto;
	@include md {
		width: 100%;
		padding: 0 4%;
	}
}

// grid
//----------------------------------------------------
.grid {
  display        : flex;
  align-items    : stretch;
  justify-content: flex-start;
  flex-wrap      : wrap;
  &-top            { align-items:     flex-start; }
  &-middle         { align-items:     center; }
  &-bottom         { align-items:     flex-end; }
  &-center         { justify-content: center; }
  &-end            { justify-content: flex-end; }
  &-between        { justify-content: space-between; }
  &-nowrap         { flex-wrap:       nowrap; }
  &-reverse        { flex-direction:  row-reverse; }
  &-column         { flex-direction:  column; }
  &-column-reverse { flex-direction:  column-reverse; }

  @for $i from 1 through $grid-column {
    &-order-#{$i}  { order:       $i; }
    &-grow-#{$i}   { flex-grow:   $i; }
    &-shrink-#{$i} { flex-shrink: $i; }
  }

}
