.icon {
	display: inline-block;
	vertical-align: middle;
	&-arrow-r {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: relative;
		&:before {
			@include arrow-icon( 8px, 8px, auto, auto );
			position: absolute;
			top: 0;
			left: -2px;
			right: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
		}
	}
}

.breadcrumb {
	li {
		&:not(:first-child):before {
			@include arrow-icon( 6px, 6px, 12px, 15px );
		}
	}
}
